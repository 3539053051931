import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>

    <p>{`I'm Jonatan. I work as a front-end developer and I love web technologies.`}</p>
    <p>{`On my blog I write about overcoming problems I encounter and leave notes about stuff I'm currently learning, so you can too!
I also make various projects to make life easier for myself and others.`}</p>
    <p>{`If you have some business to do, visit `}<Link to="/contact" mdxType="Link">{`Contact`}</Link>{` page. I'll be in touch!`}</p>
    <p>{`More content is coming soon!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      